/* 
 * @Author: 曹俊杰 
 * @Date: 2020-11-09 15:37:00
 * @Module: 微头条详情
 */
 <template>
  <div class="detail"
       v-if="detailData">
    <detail-userinfo :data="detailData" />
    <div class="imageDetail-content">
      <div class="imageDetail-content-text">
        <at-text-render :text="detailData.moment_content.content"
                        :data="detailData" />
      </div>
      <div class="imageDetail-content-images">
        <div v-if="media_infos.length===1"
             v-viewer="{title:false}">
          <el-image style="width: 100%;"
                    class="imageDetail-content-images-border"
                    :src="media_infos[0].src">
          </el-image>
        </div>
        <div v-else-if="media_infos.length===4"
             class="imageDetail-content-images "
             v-viewer="{title:false}">
          <el-image style="width:327px;height:327px;"
                    class="imageDetail-content-images-itemfour imageDetail-content-images-border"
                    v-for="(item,index) in media_infos"
                    :key="index"
                    fit="cover"
                    :src="item.src" />
        </div>
        <div v-else
             class="imageDetail-content-images"
             v-viewer="{title:false}">
          <el-image style="width:215px;height:216px;"
                    class="imageDetail-content-images-item imageDetail-content-images-border"
                    v-for="(item,index) in media_infos"
                    :key="index"
                    fit="cover"
                    :src="item.src" />
        </div>
      </div>
    </div>
    <tip-off :data="detailData"
             @onLike="onLike"
             @onTipOff="onTipOff" />
    <comment :data="detailData" />
    <recommend :data="detailData" />
    <menu-list :data="detailData" />
  </div>
</template>
 <script>
import detailMixins from "./detailMixins"
import atTextRender from "@/components/atTextRender"
export default {
  components: {
    atTextRender
  },
  mixins: [detailMixins],
  data () {
    return {};
  },
  mounted () { },
  methods: {},
  computed: {
    media_infos () {
      if (this.detailData) {
        console.log(this.detailData)
        return this.detailData.moment_content.media_infos || []
      }
      else {
        return []
      }
    }
  }, //计算属性
};
 </script>
 <style lang='scss' scoped>
@import url("./style.scss");
.imageDetail-content {
  &-text {
    font-size: 16px;
    color: #333333;
    line-height: 25px;
  }
  &-images {
    margin-top: 14px;
    &-border {
      border-radius: 4px;
    }
    &-item:nth-child(3n-1) {
      margin-left: 4px;
      margin-right: 4px;
    }
    &-itemfour:nth-child(2n) {
      margin-left: 4px;
    }
  }
}
</style>